.popup-content {
	background-color: var(--primary-color);
	padding: 2.5rem 5rem;
	width: 50%;
	border-radius: var(--border-radius);
}

.episodes-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.episode-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	box-shadow: 4px 10px 18px rgba(109, 87, 87, 0.349);
}
.episode-form label {
	width: 100%;
}

.close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: var(--secondary-color-dim);
	border-radius: 18px;
	border: 1px solid var(--primary-color);
}

.close:hover {
	background: var(--secondary-color);
}

@media (max-width: 768px) {
	.popup-content {
		width: 76%;
		padding: 1.5rem 2rem;
	}
}
