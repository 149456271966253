section.podcast-info {
	position: relative;
}

div.image-stack {
	width: 100%;
	position: relative;
}

div.image-stack .podcast-thumbnail {
	width: 150px;
	height: 150px;
	position: absolute;
	bottom: 2rem;
	left: 2rem;
	border-radius: var(--border-radius);
}

div.image-stack .podcast-banner {
	width: 100%;
	height: 100%;
	min-height: 24rem;
	max-height: 30rem;
	border-radius: var(--border-radius);
}

div.cards-list {
	display: flex;
	flex-wrap: wrap;
	gap: 1.75rem;
	padding-bottom: 6rem;
}

/* style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', paddingBottom: '5rem' }} */
